/* eslint-disable react-hooks/exhaustive-deps */
import { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { CustomButton } from "./Button";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import usePagination from "../../hooks/usePagination";
import CusPagination from "./Pagination";
import dayjs from "dayjs";
import FormLabel from "@mui/material/FormLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useSelector, useDispatch } from "react-redux";
import Authorization from "../../utils/authorization";
import util from "util";
import { apiURL } from "../../services/apiUrlConfig";
import { approveMarketingActivity, resetStatus } from "../../saga/saga/approveMarketingActivity";
import { openSnackbar } from "./Toastr";
import { useLocation } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { getOfflineMA } from "../../saga/saga/offlineMarketingAct";
import { getOnlineMA } from "../../saga/saga/onlineMarketingAct";
import Loader from "./Loader";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { getPhoto } from "../../saga/saga/downloadPhotos";
import Checkbox from "@mui/material/Checkbox";
import { getBrandRepresent } from "../../saga/saga/brandRepresentAct";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#00617F",
    color: "#FFFFFF",
    "&:last-child": {
      borderTopRightRadius: "4px",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "#FFFFFF",
  },
  [`&.${tableCellClasses.root}`]: {
    padding: 5,
    border: "none",
  },
  [`&.${tableCellClasses.footer}`]: {
    backgroundColor: "#F5F5F5",
    color: "#585858",
    lineHeight: "1.4rem",
  },
}));

function CustomTableHead(props) {
  // eslint-disable-next-line no-unused-vars
  const { order, orderBy, rowCount, onRequestSort, headCells } = props;
  // eslint-disable-next-line no-unused-vars
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells?.length > 0 &&
          headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              style={{
                minWidth: headCell?.isMaxWidthApply ? headCell.minWidth : "auto",
                fontSize: 12,
                fontWeight: 400,
                letterSpacing: 0.3,
                paddingLeft: headCell.disablePadding ? 20 : 0,
              }}
            >
              {headCell.label}
            </StyledTableCell>
          ))}
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

CustomTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ActionHandlers = ({ selectedRow }) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  //redux
  const dispatch = useDispatch();
  const { viewContractID } = useSelector((state) => state.common);
  const approveMAResponse = useSelector((state) => state.approveMarketingActivity);
  const authUser = Authorization.getAuthUser();
  const { state } = useLocation();

  const handleClickOpen = (value) => {
    setOpen(true);
    setStatus(value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const submitAPI = (e, row, reasonText) => {
    e.preventDefault();
    let payload = {
      contractid: viewContractID,
      status: status === "cancel" ? "Rejected" : "Approved",
      external_id__c: row?.external_id__c,
      reason_for_rejection__c: status === "cancel" ? reasonText : null,
    };
    const { ppp } = apiURL;
    const condUrl = util.format(ppp.approveMarketingActivity, authUser?.id);
    const data = { url: condUrl, body: payload };
    dispatch(approveMarketingActivity(data));
  };

  useEffect(() => {
    if (approveMAResponse?.approveMarketingActivityError) {
      const errormsg = approveMAResponse?.approveMarketingActivityResponse?.data?.message;
      openSnackbar({
        message: errormsg || "Something Went Wrong",
        variant: "error",
      });
      dispatch(resetStatus());
    } else if (approveMAResponse?.approveMarketingActivityResponse?.status === 200) {
      openSnackbar({
        message: "Activity status updated Successfully",
        variant: "success",
      });
      dispatch(resetStatus());
      handleClose();
      const {
        ppp: { activities },
      } = apiURL;
      const condUrl = util.format(activities.getOneMarketingActivities, authUser?.id, viewContractID);
      const queryParams = `?activityTypeId=${state?.activities?.id}&pppstage=${"ACTIVITIES"}`;
      if (state?.activities?.id === 6) {
        dispatch(getOfflineMA(condUrl + queryParams));
      } else if (state?.activities?.id === 7) {
        dispatch(getOnlineMA(condUrl + queryParams));
      } else if (state?.activities?.id === 10) {
        dispatch(getBrandRepresent(condUrl + queryParams));
      }
    }
  }, [approveMAResponse]);

  return (
    <Box style={{ marginLeft: "-10px" }}>
      <IconButton onClick={() => handleClickOpen("approve")}>
        <CheckCircleOutlineOutlinedIcon sx={{ color: "#89D329" }} />
      </IconButton>
      <IconButton onClick={() => handleClickOpen("cancel")}>
        <CancelOutlinedIcon sx={{ color: "#f55f5f" }} />
      </IconButton>
      {approveMAResponse?.approveMarketingActivityloading ? (
        <Loader lightBg={true} />
      ) : (
        <ActionPopup open={open} handleClose={handleClose} status={status} selectedRow={selectedRow} submitAPI={submitAPI} />
      )}
    </Box>
  );
};
const BootstrapDialog = styled(Dialog)(({ theme, width }) => ({
  "& .MuiDialog-paper": {
    background: "transparent",
    boxShadow: "none",
    minWidth: width || "400px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    backgroundColor: "#ffffff",
    borderRadius: "10px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function ActionPopup({ open, handleClose, status, selectedRow, submitAPI }) {
  const [reasonText, setReasonText] = useState("");
  const [reasonTextError, setReasonTextError] = useState(false);
  const handleConfirm = (e) => {
    if (status === "approve") {
      submitAPI(e, selectedRow, reasonText);
    } else {
      if (!reasonText) {
        setReasonTextError(true);
      } else {
        submitAPI(e, selectedRow, reasonText);
        setReasonTextError(false);
      }
    }
  };
  const handleChange = (e) => {
    if (e.target.value === "") {
      setReasonTextError(true);
    } else {
      setReasonTextError(false);
    }
    setReasonText(e.target.value);
  };
  useEffect(() => {
    if (!open) {
      setReasonText("");
      setReasonTextError(false);
    }
  }, [open]);
  return (
    <Fragment>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 1, p: 2 }} id="customized-dialog-title">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            size="small"
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: (theme) => theme.palette.grey[500],
              backgroundColor: "#fff",
              borderRadius: "15px",
              width: "28px",
              height: "28px",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
          >
            <CloseIcon sx={{ color: "#ff0000" }} size="small" />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box>
            <Typography variant="subtitle1">{`Are you sure you want to ${status === "approve" ? "Approve" : "Reject"}`}</Typography>
            <Typography variant="subtitle2">{selectedRow?.activity_name__c} </Typography>
          </Box>
          {status !== "approve" && (
            <Box display={"flex"} flexDirection={"column"} sx={{ mt: 0.5 }} gap={0.5}>
              <FormLabel htmlFor="reason" sx={{ fontSize: "0.75rem" }}>
                Reason to Reject
              </FormLabel>
              <OutlinedInput
                id="reason"
                name="reason-reject"
                type="text"
                placeholder="Enter the reason"
                value={reasonText}
                onChange={handleChange}
                autoComplete="off"
                error={reasonTextError}
              />
            </Box>
          )}
          <Box display="flex" justifyContent={"flex-end"} sx={{ width: "100%", mt: 1 }}>
            <CustomButton
              label="Confirm"
              sx={{
                color: "#fff",
                borderRadius: "25px",
                backgroundColor: "rgb(0, 145, 223)",
                opacity: "1",
                "&:hover": {
                  backgroundColor: "#0D47A1",
                },
                width: "125px",
                height: "32px",
              }}
              handleClick={handleConfirm}
              className="cus-btn-arrow"
              labelClassName={"label"}
            ></CustomButton>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
function CusTableRow(props) {
  return <TableRow style={{marginTop:'-10px'}}>{props?.children}</TableRow>;
}

function CollaspeTableCell(props) {
  return (
    <StyledTableCell
      sx={{paddingBottom:props?.bp,fontSize: props?.fSize, color: props?.component ? "#323232" : "#677879", fontWeight: props?.component ? 600 : 500, width: props.width }}
    >
      {props?.children}
    </StyledTableCell>
  );
}
function AttachedPopup({ open, handleClose }) {
  const [imgURL, setImgURL] = useState("");

  const downloadPhotosResponse = useSelector((state) => state.downloadPhotos);

  useEffect(() => {
    const resp = downloadPhotosResponse?.photoList?.data;
    if (!downloadPhotosResponse?.photoLoading && resp) {
      const byteKeys = Object.keys(resp)
        .map(Number)
        .sort((a, b) => a - b);
      const maxKey = byteKeys && byteKeys.pop();
      const arr = new Uint8Array(maxKey + 1);
      byteKeys.forEach((key) => {
        arr[key] = resp[key];
      });
      const blob = new Blob([arr], { type: "image/jpeg" });
      const url = URL.createObjectURL(blob);
      setImgURL(url);
    }
  }, [downloadPhotosResponse]);

  return (
    <Fragment>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} width={"850px"}>
        <DialogTitle sx={{ m: 1, p: 2 }} id="customized-dialog-title">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            size="small"
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              color: (theme) => theme.palette.grey[500],
              backgroundColor: "#fff",
              borderRadius: "15px",
              width: "27px",
              height: "27px",
              "&:hover": {
                backgroundColor: "#fff",
              },
            }}
          >
            <CloseIcon sx={{ color: "#ff0000", fontSize: "1.25rem" }} />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ overflow: "hidden", p: "0 !important" }}>
          {imgURL && <img style={{ aspectRatio: "16 / 9" }} src={imgURL} alt="photos" />}
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
function AttachedPhotos(props) {
  const [viewPhoto, setViewPhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState({});
  //redux
  const dispatch = useDispatch();
  const downloadPhotosResponse = useSelector((state) => state.downloadPhotos);

  const getLoadData = (photoURL) => {
    const updateApi = apiURL.ppp.getDocument ?? "";
    const userDetaisl = Authorization.authUser;
    const createDocApi = util.format(updateApi, userDetaisl?.id);
    const fileKey = `events-images/${photoURL}.jpg`;
    dispatch(getPhoto(createDocApi + fileKey));
  };
  const handlePhotos = (e, photoURL) => {
    e.preventDefault();
    setViewPhoto(true);
    if (selectedPhoto?.file_url__c !== photoURL?.file_url__c) {
      getLoadData(photoURL?.file_url__c);
    }
    setSelectedPhoto(photoURL);
  };
  return (
    <Fragment>
      <Stack direction="row" spacing={2}>
        {props?.photos?.length > 0
          ? props?.photos.map((pt, i) => (
              <Button key={i} variant="outlined" size="small" onClick={(e) => handlePhotos(e, pt)}>{`View Photo ${i + 1}`}</Button>
            ))
          : "-"}
      </Stack>
      {downloadPhotosResponse?.photoLoading ? <Loader /> : viewPhoto && <AttachedPopup open={viewPhoto} handleClose={() => setViewPhoto(false)} />}
    </Fragment>
  );
}
function ControlledCheckbox(props) {
  const [checked, setChecked] = useState(props?.isChecked);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Checkbox
      checked={checked}
      onChange={!props?.readOnly ? handleChange : () => {}}
      inputProps={{ "aria-label": "controlled" }}
      readOnly={props?.readOnly}
    />
  );
}

function Multifield(props){
  return(
    (props?.id)&&(props?.id).map((itm,index) => {
      return  <span style={{marginBottom:'10px !important',fontSize:10,bottomPadding:0}}>{itm?.label+" : "+props?.selectedRow[itm?.labelid]}{index===0&&props?.id?.length>1&&','}</span>
    })
  )
}
function CusCollapse(props) {
  const { regIndex, open, collapseHeaderCells, selectedRow } = props;
  return (
    <Collapse in={open} timeout="auto" mountOnEnter unmountOnExit>
      <Box>
        <CusTableRow>
          {collapseHeaderCells?.length > 0 &&
            collapseHeaderCells.map((hc, hcIndex) => {
              return (
                <CollaspeTableCell
                  fSize={hc?.type === "DATABIND"?"11px":(regIndex === 1 ? "" : "12px")}
                  component={hcIndex === 0 || regIndex === 1 ? true : false}
                  scope="row"
                  width={hc?.minWidth}
                  key={hcIndex}
                  bp = {hc?.type === "DATABIND"?'0px !important':'0px'}

                >
                  {hc?.type === "DATABIND"?(`${hc?.label} - ${selectedRow[0][hc?.labelid]}`):hc?.label}
                </CollaspeTableCell>
              );
            })}
        </CusTableRow>
        {[...new Array(regIndex)].map((_, index) => {
          return (
            <CusTableRow>
              {collapseHeaderCells?.length > 0 &&
                collapseHeaderCells.map((hc, hcIndex) => {
                  const rowValue = selectedRow[index][hc?.id];
                  return (
                    <CollaspeTableCell
                    style={{paddingTop:0}}
                      fSize={regIndex === 1 ? "" : "12px"}
                      component={hcIndex === 0 && regIndex > 1 ? true : false}
                      width={hc?.minWidth}
                      key={hcIndex}
                      bp = {hc?.type === "DATABIND"?'0px !important':'0px'}
                    >
                      {hc?.type === "checkbox" ? (
                        <ControlledCheckbox isChecked={rowValue} readOnly={true} />
                      ) : hc?.id === "attachments" ? (
                        <AttachedPhotos photos={rowValue} />
                      ) :hc?.type === "DATABIND"?(
                          <span style={{display:'flex',height:30}}><Multifield  selectedRow={selectedRow[index]} id={hc?.id}/></span>
                      ): (
                        rowValue
                      )}
                    </CollaspeTableCell>
                  );
                })}
            </CusTableRow>
          );
        })}
      </Box>
    </Collapse>
  );
}

function Row(props) {
    // eslint-disable-next-line no-unused-vars
  const { multiLineSource, multiLine, row, headCells, rowIndex, getSelectedDetailsID, from, statusLoad, collapseHeaderCells, getSelectedRowTable } =
    props;
  const [open, setOpen] = useState(false);
  const seeDtsColor = { color: "#0091DF" };
  const actionStatus = row?.status__c;

  return (
    <Fragment>
      <CusTableRow>
        {headCells?.length > 0 &&
          headCells.map((hc) => {
            return (
              <StyledTableCell
                align={"left"}
                style={{
                  fontSize: 12,
                  paddingRight: hc.isLast ? 30 : 0,
                  paddingLeft: hc.disablePadding ? 20 : 0,
                }}
                key={hc?.id}
              >
                {hc?.userInteration === true ? (
                  actionStatus === "Submitted" ? (
                    <ActionHandlers selectedRow={row} />
                  ) : (
                    <Chip
                      label={actionStatus}
                      color={actionStatus === "Approved" ? "success" : "error"}
                      variant="outlined"
                      sx={{
                        height: "25px",
                        fontSize: "inherit",
                        border: "none",
                        backgroundColor: actionStatus === "Approved" ? "#d3efe0" : "#fbd9d3",
                      }}
                    />
                  )
                ) : hc?.type === "date" ? (
                  row[hc?.id] ? (
                    dayjs(row[hc?.id]).format("DD/MM/YYYY")
                  ) : (
                    ""
                  )
                ) : (
                  row[hc?.id]
                )}
              </StyledTableCell>
            );
          })}
        <StyledTableCell
          component="th"
          scope="row"
          sx={seeDtsColor}
          onClick={() => {
            setOpen(!open);
            getSelectedRowTable(row);
            getSelectedDetailsID(rowIndex);
          }}
          style={{ cursor: "pointer" }}
        >
          See Details
          <IconButton aria-label="expand row" size="small">
            {row?.isSeeDetailsOpen ? <KeyboardArrowUpIcon sx={seeDtsColor} /> : <KeyboardArrowDownIcon sx={seeDtsColor} />}
          </IconButton>
        </StyledTableCell>
      </CusTableRow>
      <CusTableRow>
        <TableCell colSpan={headCells?.length + 1} style={{ paddingBottom: 0, paddingTop: 0, borderTop: 0, borderBottom: "1px solid #d9d9d994" }}>
          <CusCollapse
            open={row?.isSeeDetailsOpen}
            collapseHeaderCells={collapseHeaderCells}
            regIndex={multiLine ? multiLineSource?.length : 1}
            selectedRow={multiLine ? multiLineSource : [row]}
          />
        </TableCell>
      </CusTableRow>
    </Fragment>
  );
}

export default function CollapsibleTable({
  from,
  rows,
  headCells,
  getFilterData,
  isPagination,
  isTableFooter,
  getAPICall,
  totalCount,
  statusLoad,
  collapseHeaderCells,
  getSelectedRowTable,
  multiLine = false,
  multiLineSource = null,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selectedDetailsID, setSelectedDetailsId] = useState(null);
  const [rowsData, setRowsData] = useState();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    if (rows) setRowsData(rows);
  }, [rows]);

  const { currentData, currentPage, totalPages, setCurrentPage, setItemsPerPage } = usePagination(rowsData, 10);

  const LoadWhile = () => {
    return [...Array(4)].map((item, itemIndex) => {
      return (
        <TableRow key={itemIndex}>
          <TableCell
            colSpan={headCells?.length + 1}
            sx={{ textAlign: "center" }}
            style={{
              borderBottom: "0px solid transparent",
              paddingBottom: 0.5,
              paddingRight: 2,
              paddingLeft: 0,
            }}
          >
            <Skeleton animation="wave" variant="rectangular" sx={{ height: 60, width: "100%", backgroundColor: "#F2F2F2" }} />
          </TableCell>
        </TableRow>
      );
    });
  };
  const getSelectedDetailsID = (rowIndex) => {
    if (currentData?.length) {
      const updatedSeeDetails = currentData.map((cd, index) => {
        if (rowIndex === index) {
          return (cd = { ...cd, isSeeDetailsOpen: !cd?.isSeeDetailsOpen });
        } else {
          return (cd = { ...cd, isSeeDetailsOpen: false });
        }
      });
      setRowsData(updatedSeeDetails);
    }
    setSelectedDetailsId(rowIndex);
  };
  return (
    <Fragment>
      <Box>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: {
              xl: 1000,
              lg: 380,
            },
          }}
          elevation={0}
        >
          <Table sx={{ minWidth: 750 }} aria-label="collapsible table" stickyHeader>
            <CustomTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows?.length} headCells={headCells} />
            <TableBody>
              {!statusLoad ? (
                currentData?.length > 0 ? (
                  currentData.map((row, rowIndex) => (
                    <Row
                      key={rowIndex}
                      row={row}
                      headCells={headCells}
                      statusLoad={statusLoad}
                      multiLine={multiLine}
                      multiLineSource={row[multiLineSource] ?? []}
                      collapseHeaderCells={collapseHeaderCells}
                      getSelectedRowTable={getSelectedRowTable}
                      getSelectedDetailsID={getSelectedDetailsID}
                      selectedDetailsID={selectedDetailsID}
                      rowIndex={rowIndex}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={headCells?.length + 1} sx={{ textAlign: "center" }}>
                      No records found!
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <LoadWhile />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        {isPagination && rows?.length > 0 && (
          <CusPagination
            count={totalPages}
            page={currentPage}
            handlePageChange={(e, page) => setCurrentPage(page)}
            handleRowsPerChange={setItemsPerPage}
          />
        )}
      </Box>
    </Fragment>
  );
}
