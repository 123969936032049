/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useRef, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Paper, Box  } from '@mui/material';
import { Table, TableBody, TableContainer, TableHead, TableRow, } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import { apiURL } from '../../../../services/apiUrlConfig';
import authorization from '../../../../utils/authorization';
import util from 'util'
import { useDispatch, useSelector } from "react-redux";
import { getOrderManagementAct as getSalesVolumeAct, resetStatus } from "../../../../saga/saga/orderManagementActSaga";
import { CONTRACT_YEAR } from '../../../../constants'

const tierArray = ['base','silver','gold','platinum']

const headCells = [
  {
    id: "tiers",
    numeric: false,
    disablePadding: false,
    label: "Tiers",
    minWidth: 200,
    isInputBox: false,
    isLast: false,
  },
  {
    id: "netInvoicedPurchases",
    numeric: false,
    disablePadding: false,
    label: "Net Invoiced Purchases",
    minWidth: 120,
    isInputBox: false,
    isLast: false,

  },
  
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#00617F',
      color: theme.palette.common.white,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 11,
      fontWeight: 100
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      paddingTop: 10,
      paddingBottom: 9,
      fontWeight: 100
  },
}));
const LoadWhile = (statusLoad) => {
      return statusLoad ? (
          [...Array(4)].map((item, itemIndex) => {
              return (
                  <TableRow key={itemIndex}>
                      <TableCell
                          colSpan={headCells?.length + 1}
                          sx={{ textAlign: "center" }}
                          style={{
                              borderBottom: "0px solid transparent",
                              paddingBottom: 0.5,
                              paddingRight: 2,
                              paddingLeft: 0,
                          }}
                      >
                          <Skeleton
                              animation="wave"
                              variant="rectangular"
                              sx={{ height: 60, width: "100%", backgroundColor: "#F2F2F2" }}
                          />
                      </TableCell>
                  </TableRow>
              );
          })
      ) : (
          <TableRow>
              <TableCell colSpan={headCells?.length + 1} sx={{ textAlign: "center" }}>
                  {" "}
                  No records found!
              </TableCell>
          </TableRow>
      );
  };

const SalesVolume = () => {
  const inputRef = useRef(null)
  const [rows, setRows] = useState([])
  const [cysales, setCysales] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [statusLoad,setStatusLoad] = useState(false);
  const [highlight, setHighlight] = useState(0)
  const authUser = authorization.getAuthUser();
  const dispatch = useDispatch();
  const getSalesVolumeActivities = useSelector((state) => state.OrderManagementAct?.actOrderManagementList);
  const getConditionalValues = useSelector(
    (state) => state.contracts.condsAndUncondsObj
  );
  const getLoadData = () => {
    const {
      ppp: { activities },
    } = apiURL;
    const condUrl = util.format(activities.getSalesVolumeActivities, authUser?.id, getConditionalValues?.contractId);
    const queryParams = `?pppstage=${"ACTIVITIES"}`
    dispatch(getSalesVolumeAct(condUrl+queryParams));
  };

  useEffect(() => {
    getLoadData();
  }, []);

  useEffect(() => {   
    keyAsColumns(getSalesVolumeActivities)
  }, [getSalesVolumeActivities]);

  useEffect(() => {
    return () => {
      dispatch(resetStatus());
   }
}, [])



const keyAsColumns = (val) => {
    let keys = Object.keys(val)
    let rows = []
    for(let i = 0; i < keys.length; i++){
      if(['base','silver','gold','platinum'].includes(keys[i])){      
      rows = [...rows, {
        'tiers': keys[i].charAt(0).toUpperCase() + keys[i].slice(1),
        'netInvoicedPurchases' :  (i>0 && keys[i] !== 'base' )  ?
      (keys[i] !== 'platinum') ? val.currency+' ' + (val[keys[i-1]] + 1).toLocaleString() +' - ' + val.currency+' '+ val[keys[i]].toLocaleString() : val.currency+' ' +(val[keys[i-1]]+1).toLocaleString() + ' +'
        : val.currency+' ' + 0 + ' - ' + val.currency+' ' +  val[keys[i]].toLocaleString()  }]
      }
    }   
    setRows( rows);
    const getCysales = val.cysales && val.cysales.toLocaleString()
    setCysales((val.currency !== undefined && getCysales !== undefined)  ? val.currency+' '+getCysales : '')
    
    if(Object.keys(val)?.length){
      for (let index = 0; index < tierArray.length; index++) {
        const element = tierArray[index];
        const extraIndex = index===0?0:1
        if (val?.cysales <= val[element]+extraIndex && highlight === 0) {
          setHighlight(index);
          break; 
        }
        else if(!isNaN(val?.cysales)){
          setHighlight(tierArray?.length-1);
        }else{
          setHighlight(null)
        }
      }
    }
  }

  return (
    <Fragment>

      <Box display="flex" flexDirection="column" gap={1.5}>       
      
        <Paper elevation={0} sx={{ width: "100%" }}>
            <Box sx={{  m: 3 }}>
        <Typography>Year: {CONTRACT_YEAR ? CONTRACT_YEAR-1 : '' }</Typography>
        <Typography sx={{  mb: 1 }}>Year-To-Date Net Invoiced Purchases: <strong>{cysales}</strong></Typography>
       
       
      <TableContainer style={{ overflow: 'auto', height: 370 }} ref={inputRef} component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{ backgroundColor: '#1a648d' }}>
                        <TableRow>
                            {
                                headCells.map((item, index) => (
                                    <StyledTableCell key={index} width={item.minWidth} sx={{ color: 'white', fontWeight: 'normal' }}>{item.label}</StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rows?.length > 0 && headCells?.length > 0) ? rows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ 
                                  border: 0, 
                                  background: index===highlight?'#EBF0F2':''}}
                            >
                                {headCells?.length > 0 && headCells.map((itm,i) => {
                                    return <StyledTableCell key={i} style={{ minWidth: itm.minWidth }} align="left">{(row[itm?.id] && row[itm?.id]) ? (row[itm?.id] && row[itm?.id]) : '-'}</StyledTableCell>
                                })}
                            </TableRow>
                        )) : <LoadWhile statusLoad = {statusLoad} />}

                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
        </Paper>
      
      </Box>
    </Fragment>
  )
}


export default SalesVolume;